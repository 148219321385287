@import '~antd/lib/style/index';

.finalWords {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
}

.finalWord {
	width: 100%;
	text-align: center;
	color: #fffbe6;
	position: absolute;
	opacity: 0;
	font-weight: 400;
	font-display: block;
}



.finalWordP {
	text-align: center;
	bottom: 63%;
	width: 100%;
	font-family: "Dalek-Philosophy";
	font-size: calc(15*var(--canvasvw));
	font-weight: 400;
	.wideCanvas & {
		font-size: calc(11*var(--canvasvw));
		bottom: 62%;
	}
}

.finalWordP1 {
	position: absolute;
	width: 100%;
	bottom: 0;
	opacity: 1;
	font-family: "Dalek-Philosophy";
}

.finalWordP2 {
	position: absolute;
	width: 100%;
	bottom: 0;
	opacity: 0;
	font-weight: 200;
	font-family: "Unifraktur-Philosophy";
	font-size: calc(19*var(--canvasvw));
	bottom: calc(-2*var(--canvasvw));
	.wideCanvas & {
		font-size: calc(12*var(--canvasvw));
		bottom: calc(-1*var(--canvasvw));
	}
}

.finalWordP3 {
	position: absolute;
	width: 100%;
	bottom: 0;
	opacity: 0;
	font-family: "Primitive-Philosophy";
}

.finalWordA {
	bottom: 57.4%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 700;
	.wideCanvas & {
		font-size: calc(5*var(--canvasvw));
		bottom: 54.4%;
	}
}

.finalWordC {
	bottom: 47%;
	font-family: "Ailerons-Contemporary-Issues";
	font-size: calc(12*var(--canvasvw));
	letter-spacing: calc(0.6*var(--canvasvw));
	.wideCanvas & {
		font-size: calc(9*var(--canvasvw));
		letter-spacing: calc(0.4*var(--canvasvw));
		bottom: 33%;
	}
}

.finalWordC1 {
	position: absolute;
	width: 100%;
	bottom: 0;
	opacity: 1;
}

.finalWordC2 {
	position: absolute;
	width: 100%;
	bottom: 0;
	opacity: 0;
	font-family: 'TNG-Contemporary-Issues';
	font-weight: 200;
	bottom: calc(-0.9*var(--canvasvw));
	.wideCanvas & {
		bottom: calc(-0.7*var(--canvasvw));
	}
}

.finalWordC3 {
	position: absolute;
	width: 100%;
	bottom: 0;
	opacity: 0;
	font-family: 'Halogen-Contemporary-Issues';
	font-size: calc(11*var(--canvasvw));
	.wideCanvas & {
		letter-spacing: calc(0.5*var(--canvasvw));
		font-size: calc(9*var(--canvasvw));
	}
}


.finalWordI {
	bottom: 40%;
	font-family: "Ailerons-Contemporary-Issues";
	font-size: calc(12*var(--canvasvw));
	letter-spacing: calc(0.6*var(--canvasvw));

	.wideCanvas & {
		font-size: calc(9*var(--canvasvw));
		letter-spacing: calc(0.4*var(--canvasvw));
		bottom: 15%;
	}
}

.finalCredit {
	position: absolute;
	bottom: 16%;
	color: #fffbe6;
	opacity: 0;
	font-weight: 700;
	font-size: calc(4.4*var(--canvasvw));

	.wideCanvas & {
		font-size: calc(2*var(--canvasvw));
		bottom: 6%;
	}
}

@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';