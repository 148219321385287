@import '~antd/lib/style/index';

.eyescale {
  --eyesize: 300px;
  position: absolute;
  left: calc(50% - (var(--eyesize) / 2));
  bottom: calc(64% - (var(--eyesize) / 1.5));

  .wideCanvas & {
    bottom: calc(60% - (var(--eyesize) / 1.5));
  }
}

.winkyeye {
  opacity: 0;
  background: #fffbe6;
  width: var(--eyesize);
  height: var(--eyesize);
  border: solid 1px #fffbe6;
  border-radius: 80% 7%;
  transform: rotate(45deg);
  overflow: hidden;
  box-shadow: inset calc(var(--eyesize) / 50) calc(var(--eyesize) / -50) calc(var(--eyesize) * 0.1) #000000;
}

.winkyiris {
  transform: rotate(-45deg);
  display: block;
  width: calc(var(--eyesize) * 0.65);
  height: calc(var(--eyesize) * 0.65);
  border: solid 1px #000;
  border-radius: 50%;
  position: relative;
  top: calc(var(--eyesize) * 0.1725);
  left: calc(var(--eyesize) * 0.1725);
  display: flex;
  justify-content: center;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, #001529 100%);
} 

.winkypupil {
  background: black;
  color: #ffd666;
  border-radius: 50%;
  margin-top: calc(var(--eyesize) / 8);;
  height: calc(var(--eyesize) * 0.4);;
  width: calc(var(--eyesize) * 0.4);
  display: flex;
  line-height: calc(var(--eyesize) * 0.4);
  justify-content: center;
  font-size: calc(var(--eyesize) * 0.35);
}

.winkyeyelid {
  background: #001529;
  position: relative;
  top: calc(var(--eyesize) * -0.75);;
  right: calc(var(--eyesize) * 0.25);;
  height: var(--eyesize);
  width: calc(var(--eyesize) * 1.8);;
  border: solid 1px #001529;
  transform: rotate(-45deg);
  transition: top 0.5s, right 0.5s;
}

.winkyeyelidopen {
  top: calc(var(--eyesize) * -3);
  right: calc(var(--eyesize) * 2);
}

@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';