@import '~antd/lib/style/index';
@import '../../breakpoints';

.promptHeading {
	color: rgba(0,0,0,0.7);
}

.promptDate {
	color: rgba(0,0,0,0.7);
}

.promptText {
	font-family: Echo, Ping, sans;
	margin-left: 2em;
	color: rgba(0,0,0,0.6);
}

.promptLine {
	position: relative;
}

.promptHashlinkBuffer {
	position: absolute;
	top: -5em;
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';