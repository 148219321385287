@import '~antd/lib/style/index';

.ethicsDummySpace {
	opacity: 0;
}

.ethicsVeggie {
	position: absolute;
	top: 18%;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	font-size: calc(7*var(--canvasvw));
	font-weight: 800;
	justify-content: center;
	.wideCanvas & {
		top: 19%;
		font-size: calc(4*var(--canvasvw));
	}
}

.ethicsKill {
	color: red;
	font-size: calc(11*var(--canvasvw));
	width: 100%;
	text-align: center;
	opacity: 0;
	.wideCanvas & {
		font-size: calc(6*var(--canvasvw));
		width: auto;
		transform: translateY(-0.2em);
	}
}

.ethicsOKLetter {
	opacity: 0;
}

.ethicsAnimalsLetter {
	opacity: 0;
}


.ethicsIcon {
	position: absolute;
}

.ethicsKnife {
	transform-origin: 50% 50%;
	transform: scale(0.8) rotate(45deg);
}

.ethicsKnifeBlock {
	transform-origin: 180% 0 0;
	opacity: 0;
	.wideCanvas & {
		transform-origin: 250% 0 0;
	}
}

.ethicsThreeIcons {
	position: absolute;
	width: calc(17.6*var(--canvasvw));
	height: calc(15.4*var(--canvasvw));
	bottom: 100%;
	.wideCanvas & {
		width: calc(8*var(--canvasvw));
		height: calc(7*var(--canvasvw));
	}
}

.ethicsGround {
	position: absolute;
	top: 67%;
	height: 30%;
	width: 100%;
	border-top: solid #fffbe6 calc(1*var(--canvasvw));
	left: -100%;
	.wideCanvas & {
		top: 90%;
		border-top: solid #fffbe6 calc(0.5*var(--canvasvw));
	}
}

.ethicsThreeIcons1 {
	transform: translateX(calc(16*var(--canvasvw)));
	.wideCanvas & {
		transform: translateX(calc(18*var(--canvasvw)));
	}
}

.ethicsThreeIcons2 {
	transform: translateX(calc(40*var(--canvasvw)));
}

.ethicsThreeIcons3 {
	transform: translateX(calc(68*var(--canvasvw)));
}

.ethicsMeat {
	opacity: 0;
}

.ethicsOink {
	font-weight: 400;
	font-size: calc(3.6*var(--canvasvw));
	left: 106%;
	position: absolute;
	left: 110%;
	opacity: 0;
	.wideCanvas & {
		font-size: calc(2*var(--canvasvw));
		left: 120%;
	}
}

.ethicsOwe {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: absolute;
	top: 20%;
	width: 100%;
	font-size: calc(14*var(--canvasvw));
	font-weight: 800;
	transform: translateY(100vh);
	.wideCanvas & {
		top: 22%;
		font-size: calc(8*var(--canvasvw));
	}
}

.ethicsOweText {
	width: 90%;
	text-align: center;
}

.ethicsLess {
	position: relative;
}

.ethicsPrivacy {
	display: flex;
	align-content: center;
	justify-content: center;
	flex-wrap: wrap;
	position: absolute;
	top: 25%;
	width: 100%;
	font-size: calc(14*var(--canvasvw));
	font-weight: 800;
	transform: translateY(100vh);
	.wideCanvas & {
		top: 16%;
		font-size: calc(8*var(--canvasvw));
	}
}

.ethicsPrivacyText {
	text-align: center;
}

.ethicsPrivacyWord {
    color: #fffbe6;
}

.ethicsPrivacyContainer {
	width: 6em;
	position: relative;
	overflow: hidden;
}

.ethicsPrivacyWall {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: #faad14;
	height: 0.3em;
}

.ethicsJustWar {
	display: flex;
	align-content: right;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	position: absolute;
	top: 25%;
	width: 70%;
	font-size: calc(11*var(--canvasvw));
	font-weight: 800;
	transform: translateY(100vh);
	.wideCanvas & {
		top: 23%;
		font-size: calc(6*var(--canvasvw));
	}
}

.objectiveWord {
	text-align: right;
}

.ethicsOb {
	color: red;
}

.ethicsSub {
	right: 3.91em;
	color: #faad14;
	opacity: 0;
	position: absolute;
}

.ethicsDoorContainer {
	background: #001529;
 	height: 100%;
 	width: 100vw;
 	position: absolute;
	padding-top: calc(3*var(--canvasvw));
 	display: flex;
 	flex-wrap: wrap;
 	justify-content: space-around;
 	transform-origin: 50% 100%;
	transform: perspective(calc(1*var(--canvasvw)));
 	.wideCanvas & {
 		padding-top: 0;
 		height: 100vh;
 	}
}

.ethicsDoor {
	background: #fffbe6;
	color: #001529;
	height: 13%;
	width: 45%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 800;
	.wideCanvas & {
		height: 21%;
		width: 30%;
		margin-top: calc(0.8*var(--canvasvw));
	}
}


.ethicsSwing {
	position: absolute;
	background-color: #001529;
	height: 13%;
	width: 45.5%;
	transform-origin: 50% 100%;
	transform: perspective(calc(1*var(--canvasvw)));
	.wideCanvas & {
		height: 21.3%;
		width: 30.2%;
	}
}

.ethicsDoor1 {
	font-size: calc(16*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(7.2*var(--canvasvw));
	}
}

.ethicsDoor2 {
	font-size: calc(12*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(6*var(--canvasvw));
	}
}

.ethicsDoor3 {
	font-size: calc(15*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(7*var(--canvasvw));
	}
}

.ethicsDoor4 {
	font-size: calc(11.5*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(5.6*var(--canvasvw));
	}
}

.ethicsDoor5 {
	font-size: calc(8.7*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(4.2*var(--canvasvw));
	}
}

.ethicsDoor6 {
	font-size: calc(9.9*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(5*var(--canvasvw));
	}
}

.ethicsDoor7 {
	font-size: calc(12*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(5.4*var(--canvasvw));
	}
}

.ethicsDoor8 {
	font-size: calc(10*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(4.7*var(--canvasvw));
	}
}

.ethicsDoor9 {
	font-size: calc(9*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(4.8*var(--canvasvw));
	}
}

.ethicsDoor10 {
	font-size: calc(9.3*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(4.6*var(--canvasvw));
	}
}

.ethicsDoor11 {
	font-size: calc(11*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(5.5*var(--canvasvw));
	}
}

.ethicsDoor12 {
	font-size: calc(12*var(--canvasvw));
	.wideCanvas & {
	font-size: calc(7*var(--canvasvw));
	}
}

.ethicsPurpose	{
	height: 100%;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
}

.ethicsPurposeWord {
	color: #001529;
	font-weight: 800;
	width: 100vw;
	text-align: center;
	position: relative;
	top: -5%;
	font-size: calc(20*var(--canvasvw));
	height: 13%;
	.wideCanvas & {
		font-size: calc(9*var(--canvasvw));
		height: 18%;
	}
}


.ethicsHugeDoorContainer {
	height: 100%;
 	width: 100vw;
 	position: absolute;
 	background-color: #fffbe6;
}

.finalEverWonder {
	height: 100vh;
 	width: 100vw;
 	position: absolute;
 	display: flex;
 	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
}

.finalEverWonder2 {
	height: 100vh;
 	width: 100vw;
 	position: absolute;
 	display: flex;
 	flex-direction: column;
 	justify-content: center;
 	.wideCanvas & {
 		justify-content: flex-start;
 	}
}

.finalEverWonderWord {
	color: #001529;
	font-weight: 800;
	width: 100vw;
	text-align: center;
	font-size: calc(20*var(--canvasvw));
	height: 13%;
	.wideCanvas & {
		font-size: calc(18*var(--canvasvw));
		height: calc(16*var(--canvasvw));
	}
}

@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';