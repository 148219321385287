@import '~antd/lib/style/index';


.WILEverWonder {
	position: absolute;
	bottom: 83%;
	left: 6%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 800;
	display: flex;
	.wideCanvas & {
		font-size: calc(3*var(--canvasvw));
	}
}

.WILEverWonderElem {
	opacity: 0;
	position: relative;
	padding: 0px calc(0.3*var(--canvasvw));
	transform-origin: 50% 75% 0;
}

.red {
	color: red;
}

.WILMyRed {
	position: absolute;
	display: flex;
	justify-content: center;
	left: 9%;
	top: 23%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 800;
	width: 80%;
	flex-wrap: wrap;
	align-content: center;
	line-height: 170%;
	& > div {
		padding: 0.15em;
		opacity: 0;
	}
	.wideCanvas & {
		font-size: calc(5*var(--canvasvw));
		line-height: 190%;
		width: 55%;
		left: 25%;
		top: 20%;
	}
	.sidebars.tallCanvas & {
		width: 70%;
		left: 14%;
	}
}

.WILchunk {
	text-align: center;
}

.WILEyes {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	left: 10%;
	top: 20%;
	width: 80%;
	font-size: calc(8*var(--canvasvw));
	line-height: 170%;
	font-weight: 800;
	& > div {
		padding: 0.15em;
		opacity: 0;
	}
	.wideCanvas & {
		font-size: calc(5*var(--canvasvw));
		padding: calc(1*var(--canvasvw)) calc(9*var(--canvasvw)) 0 calc(3*var(--canvasvw));
		line-height: 190%;
		left: 13%
	}
	.sidebars.tallCanvas & {
		width: 70%;
		left: 15%;
	}
}


.WILToBeContainer 	{
	position: absolute;
	justify-content: center;
	height: 100%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 800;
	width: 100%;
	flex-wrap: wrap;
	align-content: center;
	line-height: 170%;
	& > div {
		padding: 0.15em;
	}
	.wideCanvas & {
		font-size: calc(3*var(--canvasvw));
		line-height: 190%;
		width: 85%;
	}
}

.dotpadding {
	padding: 0px calc(0.3*var(--canvasvw));
}

.WILToBeIntro {
	position: absolute;
	bottom: 78%;
	left: calc(15*var(--canvasvw));
	font-size: calc(5.2*var(--canvasvw));
	font-weight: 800;
	line-height: 50%;
	display: flex;
	opacity: 0;
	.wideCanvas & {
		font-size: calc(3*var(--canvasvw));
		right: 24%;
		bottom: 75%;
	}
}

.WILToBeItem {
	position: absolute;
	font-size: calc(4*var(--canvasvw));
	max-width: calc(16*var(--canvasvw));
	line-height: calc(4.8*var(--canvasvw));
	text-align: center;
	opacity: 0;
	font-weight: 400;
	.wideCanvas & {
		font-size: calc(2*var(--canvasvw));
		line-height: calc(2.6*var(--canvasvw));
		font-weight: 800;
	}
}

.WILToBeItem1 {
	top: 40%;
	left: 66%;
}

.WILToBeItem2 {
	top: 60%;
	left: 12%;
}

.WILToBeItem3 {
	top: 30%;
	left: 36%;
}

.WILToBeItem4 {
	top: 34%;
	left: 9%;
}

.WILToBeItem5 {
	top: 76%;
	left: 82%;
}

.WILToBeItem6 {
	top: 50%;
	left: 75%;
}

.WILToBeItem7 {
	top: 70%;
	left: 45%;
}

.WILToBeItem8 {
	top: 73%;
	left: 4%;
}

.WILToBeItem9 {
	top: 50%;
	left: 55%;
}

.WILToBeItem10 {
	top: 42%;
	left: 20%;
}

.WILToBeItem11 {
	top: 82%;
	left: 66%;	
}

.WILToBeItem12 {
	top: 86%;
	left: 38%;
}

.WILToBeItem13 {
	top: 86%;
	left: 10%;
}

.WILToBeItem14 {
	top: 58%;
	left: 38%;
}

.WILYouYouContainer {
	position: absolute;
	justify-content: center;
	height: 100%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 800;
	width: 100%;
	flex-wrap: wrap;
	align-content: center;
	line-height: 170%;
	opacity: 0;
	.wideCanvas & {
		font-size: calc(5*var(--canvasvw));
		line-height: 190%;
		width: 85%;
	}
}

.WILWhatMakes {
	position: absolute;
	top: 20%;
	left: 26%;
	.wideCanvas & {
		top: 15%;
		left: 32%;
	}
	.sidebars & {
		left: 31%;
	}
}

.WILYou {
	position: absolute;
	font-size: calc(20*var(--canvasvw));
	bottom: calc(50% - 7*var(--canvasvw));
	left: calc(45% - 14*var(--canvasvw));
	.wideCanvas & {
		font-size: calc(14*var(--canvasvw));
		bottom: calc(55% - 7*var(--canvasvw));
		left: calc(50% - 14*var(--canvasvw));
	}
}

.WILYou1 {
	transform-origin: 50% 50% 0;
}

.WILYou2 {
	transform-origin: 50% 50% 0;
}

.WILQMark {
	position: absolute;
	bottom: 18%;
	font-size: calc(13*var(--canvasvw));
	left: calc(50% - 1*var(--canvasvw));
	.wideCanvas & {
		bottom: 14%;
		font-size: calc(8*var(--canvasvw));
		left: calc(50% - 1*var(--canvasvw));
	}
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';