@import '~antd/lib/style/index';

.newsCanTrust {
	opacity: 0;
	position: absolute;
	width: 100%;
	text-align: center;
	top: 20%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 800;
	.wideCanvas & {
		font-size: calc(3*var(--canvasvw));
		top: 18%;
	}
}

.newsTrustablesContainerContainer {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	width: 100%;
	position: absolute;
	top: 29%;
	justify-content: center;
	opacity: 0;
}

.newsTrustablesContainer {
	width: 46%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	.wideCanvas & {
		width: 44%;
	}
}

.newsTrustable {
	position: relative;
	opacity: 0;
	font-weight: 800;
	text-transform: capitalize;
	transform-origin: 50% 100%;
	color: #faad14;
	font-size: calc(6.3*var(--canvasvw));
	line-height: 2.4em;
	.wideCanvas & {
		font-size: calc(3.2*var(--canvasvw));
		line-height: 1.8em;
	}
}

.newQMark {
	opacity: 0;
}


.newspaperContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 27%;
  .wideCanvas & {
  	top: 20%;
   }
}

.newspaper {
  height: calc(80*var(--canvasvw));
  width: calc(100*var(--canvasvw));
  background: #fffbe6;
  position: relative;
  opacity: 0;
  border-radius: 0 0 calc(3*var(--canvasvw)) calc(3*var(--canvasvw));
  overflow: hidden;
  .wideCanvas & {
  	height: calc(32*var(--canvasvw));
  	width: calc(40*var(--canvasvw));
  	border-radius: 0 0 var(--canvasvw) var(--canvasvw);
  }
}


.newsHeadline {
  text-align: center;
  font-weight: 800;
  padding: calc(3*var(--canvasvw)) calc(2*var(--canvasvw)) calc(3*var(--canvasvw)) calc(2*var(--canvasvw));
  font-family: 'Fira Mono', monospace;
  color: #001529;
  font-size: calc(13*var(--canvasvw));
  width: calc(100*var(--canvasvw));
  line-height: 1.1;
  .wideCanvas & {
  	font-size: calc(5.2*var(--canvasvw));
  	width: calc(40*var(--canvasvw));
  	padding: calc(2*var(--canvasvw)) calc(2*var(--canvasvw)) calc(1*var(--canvasvw)) calc(2*var(--canvasvw));
  	line-height: 1.4;
  }
}

.newspaperPicture {
  position: absolute;
  width: 41%;
  height: 32%;
  bottom: 22%;
  left: 6%;
  background: #001529;
  .wideCanvas & {
  	height: 29%;
  	bottom: 12%;
  }
}

.newspaperColumns {
  display: flex;
  justify-content: space-evenly;
  height: 68%;
  width: 100%;
}


.newspaperWords {
    width: 40%;
    height: 100%;
    background: repeating-linear-gradient(transparent 0px, transparent calc(4.5*var(--canvasvw)), #001529 calc(4.6*var(--canvasvw)), #001529 calc(5.3*var(--canvasvw)));
    .wideCanvas & {
    	background: repeating-linear-gradient(transparent 0px, transparent calc(1.5*var(--canvasvw)), #001529 calc(1.5*var(--canvasvw)), #001529 calc(1.7*var(--canvasvw)));
	}
}

.news {
	display: flex;
	justify-content: center;
	overflow: hidden
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';