@import '~antd/lib/style/index';
@import '../../breakpoints';

.calendarDateList {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;

}

.contentcalendar {
	padding: 0;
	@media @biggerHero {
		padding: 10px 0px;
	}
}

.calendarDateList .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}

.calBanner {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: center;
  line-height: 110%;
  	@media @bigHero {
  		font-size: 10px;
  	}
	@media (min-width: 1080px) {
		font-size: 11px;
	}

	@media @biggerHero {
		font-size: 14px;
	}

	@media @xxl {
		font-size: 12px;
	}

	@media (min-width: 1700px) {
		font-size: 14px;
	}
}

.calendarSmallContentBox {
	border: 1px solid @gold-5;
	border-radius: 6px;
	height: 34px;
	margin: auto;
	width: 50%;
	max-width: 50px;
	min-width: 30px;
	position: relative;
	top: -12px;
}

.calendarHeaderCenter {
	display: flex;
	align-items: center;
	@media @bigHero {
		padding-top: 20px;
	}
}

.calendarHeader {
	font-family: Ping, Echo, sans;
	font-size: 14px;
	color: #595959;
	position: relative;
	display: inline-grid;
	margin: auto;
	grid-template-columns: auto auto auto;
	grid-template-rows: none;
}

.calMth {
	width: 160px;
	@media @bigHero {
		width: 220px;
	}
}

.calendarMonthChangeBtnHolder {
	width: 30px;
	padding: 0;
}

.calMonthChangeBtn {
	width: 30px;
	padding: 0px;
	margin: 0;
	position: relative;
	top: -8px;
	@media @bigHero {
		top: -14px;
	}
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';