@import '~antd/lib/style/index';
@nearBlack: #001529;

.animationCanvas {
	position: static;
	z-index: 920;
	display: flex;
	justify-content: center;
	overflow: hidden;
	--canvasvw: 1vw;
	margin: 0;
	padding: 0;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.animationCanvas.tallCanvas {
	height: 150vw;
	width: 100vw;
}

.animationCanvas.wideCanvas {
	height: 100vh;
	width: 150vh;
}

.animationCanvas.topbars.wideCanvas {
	width: 100vw;
	height: 66vw;
}

.animationCanvas.sidebars.tallCanvas {
	height: 100vh;
	width: 66vh;
}






@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';