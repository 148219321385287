@import '~antd/lib/style/index';
@nearBlack: #001529;

.animationCover {
	--vh: 1vh;
	position: absolute;
	top: 0;
	left: 0;
	height: calc(100 * var(--vh));
	width: 100vw;
	z-index: 900;
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	color: #fffbe6;
}

#animationCover {
	background: @nearBlack;

}

.animationCoverBackground {
	background-repeat: no-repeat;
	background-position: right center;
	background-size: cover;
	background-attachment: fixed;
	background-image: url("../../../img/trianglify4.svg");
	transition: opacity 3s;
}


.animationCoverContent {
	overflow: hidden;
}

.animationCoverBackgroundHidden {
	opacity: 0;
}

@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';