@import '~antd/lib/style/index';
/* This import IS NOT WORKING.  It's driving me crazy. */
/* I'm using the line below instead.  Still not working well and I'm losing my primary colors. */
/* @import '~antd/dist/antd.css'; */

@import './breakpoints';



body {
	margin: 0;
}

.App {

}

.ant-anchor-link-title {
	color: @gold-8;
}

#layoutMainContent {
	background-repeat: no-repeat;
	background-position: right center;
	background-size: cover;
	background-attachment: fixed;
	background-image: url("./img/trianglify4.svg");
}


#title {
	margin-top: calc((100vw / 24) - 32px);
	@media @lg {
		margin-top: -30px;
		@media @largeHeroHeight {
			margin-top: 3vh;
		}
	}
}


.heroCard {
		background: white;
		margin-bottom: 60px;
	@media @lg {
		background: @gold-5;
		margin-bottom: 180px;
		box-shadow: @box-shadow-base;
	}
}

.textCenter {
	text-align: center;
	color: rgba(0, 0, 0, 0.7);
}

.textLeft {
	text-align: left;
}

.ant-anchor-ink::before {
	background: @gold-8 !important;
}

.shrunkenSubmenu {
	max-height: 0;
}

@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';