@import '~antd/lib/style/index';
@import '../../../breakpoints';

@yellowish: rgba(255,251,230,0.15);

.CompEverWonder {
	position: absolute;
	bottom: 83%;
	left: 6%;
	font-size: calc(8*var(--canvasvw));
	font-weight: 800;
	display: flex;
	.wideCanvas & {
		font-size: calc(3*var(--canvasvw));
	}
}

.CompEverWonderElem {
	position: relative;
	padding: 0px calc(0.3*var(--canvasvw));
	transform-origin: 50% 75% 0;
}


.CompBGStrings {
	position: absolute;
	font-size: calc(12*var(--canvasvw));
	font-family: 'Fira Mono', serif;
	font-weight: 800;
	display: flex;
	opacity: 0.13;
	transform: rotate(-4deg);
	transform-origin: 100% 50%;
	overflow: visible;
	.wideCanvas & {
		font-size: calc(10*var(--canvasvw));
	}
}

.CompBGString1 {
	top: 64%;
	right: 120%;
}

.CompBGString2 {
	top: 9%;
	right: 100%;
	opacity: 0.11;
	font-size: calc(8*var(--canvasvw));
	.wideCanvas & {
		font-size: calc(6*var(--canvasvw));
		top: 12%
	}
}

.CompBGString3 {
	top: 39%;
	right: 130%;
	font-size: calc(6*var(--canvasvw));
	opacity: 0.08;
	.wideCanvas & {
		font-size: calc(4*var(--canvasvw));
		top: 39%;
	}
}

.CompWorry {
	opacity: 0;
	overflow: visible;
	position: absolute;
	top: 24%;
	font-weight: 800;
	text-align: center;
	.wideCanvas & {
		font-size: calc(4.4*var(--canvasvw));
		height: 100vh;
		width: 180vh;
	}
	.topbars.wideCanvas & {
		width: 100vw;
		height: 66vw;
	}
	.tallCanvas & {
		font-size: calc(7*var(--canvasvw));
		height: 150vw;
		width: 100vw;
		top: 23%;
	}
	.sidebars.tallCanvas & {
		height: 100vh;
		width: 66vh;
	}
}

.CompWorries {
	position: relative;
	.tallCanvas & {
		top: calc(9*var(--canvasvw));
	}
}

.GATTACAletter {
	display: inline-block;
}

.CompUhOh {
	position: absolute;
	width: 100%;
	.wideCanvas & {
		font-size: calc(7*var(--canvasvw));
	}
	.tallCanvas & {
		font-size: calc(10*var(--canvasvw));
	}
}

.CompGE {
	opacity: 0;
}

.ENHANCEletter {
	display: inline-block;
	opacity: 0;
}

.CompAI1s {
	opacity: 0;
}

.CompAI0s {
	opacity: 0;
}

.CompArtificial {
	opacity: 0;
}

.CompIntelligence {
	opacity: 0;
}


.ComputerIcon {
  --comp: #fffbe6;
  --screen: #001529;
  display: flex;
  justify-content: center;
  transform-origin: 44.918% 38% 0;
  .tallCanvas & {
  	transform-origin: 46% 40% 0;
  }
}


.comp-elements {
  display: flex;
  justify-content: center;
  padding-top: calc(1.5*var(--canvasvw));
  flex-direction: column;
}

.comp-center {
  display: flex;
  justify-content: center;
}

.comp-screen-border {
  background-color: var(--comp);
  display: inline-block;
  border-radius: calc(0.6*var(--canvasvw));
}

.comp-screen {
  height: calc(7*var(--canvasvw));
  width: calc(11*var(--canvasvw));
  background: var(--screen);
  margin: var(--canvasvw);
}

.monitor-stem {
  width: calc(1.5*var(--canvasvw));
  height: calc(2*var(--canvasvw));
  background: var(--comp);
}

.monitor-base {
  background: var(--comp);
  width: calc(8*var(--canvasvw));
  height: calc(0.8*var(--canvasvw));
  border-radius: calc(0.6*var(--canvasvw)) calc(0.6*var(--canvasvw)) 0 0;
}

.comp-machine {
  height: calc(12.6*var(--canvasvw));
  width: calc(6*var(--canvasvw));
  background: var(--comp);
  margin: var(--canvasvw) 0 0 calc(2*var(--canvasvw));;
  border-radius: calc(0.6*var(--canvasvw)) calc(0.6*var(--canvasvw)) 0 0;
}

.comp-center-line {
  display: flex;
  justify-content: center;
}

.comp-fan-line {
  width: calc(4*var(--canvasvw));
  height: calc(0.3*var(--canvasvw));
  background: var(--screen);
  margin-top: calc(1.7*var(--canvasvw));
  margin-bottom: calc(-1*var(--canvasvw));
}

.CompUpload {
	position: absolute;
	width: 100%;
	padding: 0 calc(10*var(--canvasvw));
	font-size: calc(6*var(--canvasvw));
	bottom: calc(25% + 4*var(--canvasvw));
	opacity: 0;
	font-weight: 800;
	text-align: center;
	.wideCanvas & {
		font-size: calc(3*var(--canvasvw));
		bottom: calc(10% + 1*var(--canvasvw));
		padding: 0 calc(10*var(--canvasvw));
	}
}

.CompScreenGridContainer {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
  	height: 100vh;
}

.CompScreenGrid {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(90deg, transparent 0px, transparent 60px, @yellowish 60px, @yellowish 62px), repeating-linear-gradient(transparent 0px, transparent 60px, @yellowish 60px, @yellowish 62px);
  @media @lg {
  	background: repeating-linear-gradient(90deg, transparent 0px, transparent 98px, @yellowish 98px, @yellowish 100px), repeating-linear-gradient(transparent 0px, transparent 98px, @yellowish 98px, @yellowish 100px);
	}
}

.CompTyping {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	font-weight: 800;
	flex-wrap: wrap;
	vertical-align: text-bottom;

	font-family: 'VT323', monospace;
	top: 26%;
	font-size: calc(12*var(--canvasvw));
	padding: 0 1em;
	.wideCanvas & {
		top: 20%;
		font-size: calc(6.6*var(--canvasvw));
		padding: 0 4em;
	}
}

.CompTypingWord {
	padding: 0.2em 0.26em;
	line-height: 0.5em;
}

.CompSimulationLetter {
	opacity: 0;
	padding-bottom: 0.3em;
}

.CompWhoa {
	position: absolute;
	opacity: 0;
	bottom: 22%;
	right: 15%;
	font-family: 'VT323', monospace;
	font-size: calc(8*var(--canvasvw));
	.wideCanvas & {
		bottom: 15%;
		font-size: calc(3.4*var(--canvasvw));
	}
}


@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';