@import '../../breakpoints';
@import '~antd/lib/style/index';


.contentColumn {
	min-width: calc(100vw / 24 * 22);
	margin-top: 20px;
	margin-bottom: 30px;
	@media @lg {
		background: @gold-5;
		min-width: 10px;
		padding: 12px;
		margin-top: 80px;
		margin-bottom: 100px;
	}
}


.contentFontInfo {
	text-align: left;
	font-weight: 500;
	font-size: 16px;

	h2 {
		font-weight: 100;
		font-size: 24px;
		text-transform: uppercase;

	}
	h3 {
		font-weight: 100;
		font-size: 18px;
		margin-top: -6px;
		margin-bottom: 20px;
	}
	@media @bigHero {
		padding: 10px 60px;
		padding-bottom: 40px;
		font-size: 22px;
		h2 {
			font-size: 42px;
			padding-top: 20px;
			line-height: 56px;

		}
		h3 {
			margin-top: -20px;
			margin-bottom: 20px;
			font-size: 28px;
			font-weight: 100;
		}
	}
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';