@import '../../../node_modules/antd/lib/style/index';
@import '../../breakpoints.less';

@heroBoxBackground: @gold-2;
@heroNearBlack: #001529;

/*
@font-face {
  font-family: 'Primitive-Philosophy';
  font-display: block;
  font-style: normal;
  font-weight: normal;
  src: local('Primitive-Philosophy'), 
       url('../../fonts/Primitive-subset.woff2') format("woff2");
  unicode-range: U+50,U+68,U+69,U+6C,U+6F,U+70,U+73,U+79;
}

@font-face {
  font-family: 'Dalek-Philosophy';
  font-style: normal;
  font-weight: normal;
  src: local('Dalek-Philosophy'), 
       url('../../fonts/DalekPinpointBold-subset.woff2') format("woff2");
  unicode-range: U+50,U+68,U+69,U+6C,U+6F,U+70,U+73,U+79;
  font-display: block;
} 

@font-face {
  font-family: 'Ailerons-Contemporary-Issues';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/Ailerons-Typeface-subset.woff2') format("woff2");
  unicode-range: U+43,U+49,U+61,U+65,U+6D-70,U+72-75,U+79;
  font-display: block;
} 

@font-face {
  font-family: 'Elianto-Contemporary-Issues';
  font-style: normal;
  font-weight: normal;
  src: local('Elianto-Contemporary-Issues'), 
       url('../../fonts/Elianto-Regular-subset.woff2') format("woff2");
  unicode-range: U+41,U+43,U+45,U+49,U+4D-50,U+52-55,U+59;
  font-display: block;
} 

@font-face {
  font-family: 'Halogen-Contemporary-Issues';
  font-style: normal;
  font-weight: normal;
  src: local('Halogen-Contemporary-Issues'), 
       url('../../fonts/HalogenbyPixelSurplus-Regular-subset.woff2') format("woff2");
  unicode-range: U+43,U+49,U+61,U+65,U+6D-70,U+72-75,U+79;
  font-display: block;
} 


@font-face {
  font-family: 'TNG-Contemporary-Issues';
  font-style: normal;
  font-weight: normal;
  src: local('TNG-Contemporary-Issues'), 
       url('../../fonts/TNG_Title-subset.woff2') format("woff2");
  unicode-range: U+43,U+49,U+61,U+65,U+6D-70,U+72-75,U+79;
  font-display: block;
}


@font-face {
  font-family: 'AnglicanText-Philosophy';
  font-style: normal;
  font-weight: normal;
  src: local('AnglicanText-Philosophy'), 
       url('../../fonts/AnglicanText-subset.woff2') format("woff2");
  unicode-range: U+50,U+68,U+69,U+6C,U+6F,U+70,U+73,U+79;
  font-display: block;
} 

@font-face {
  font-family: 'Sail-Philosophy';
  font-style: normal;
  font-weight: normal;
  src: local('Sail-Philosophy'),
       url('../../fonts/Sail-Regular-subset.woff2') format("woff2");
  unicode-range: U+50,U+68,U+69,U+6C,U+6F,U+70,U+73,U+79;
  font-display: block;
}

@font-face {
  font-family: 'Unifraktur-Philosophy';
  font-style: normal;
  font-weight: normal;
  src: local('Unifraktur-Philosophy'),
       url("../../fonts/UnifrakturCook-Bold-subset.woff2") format("woff2");
  unicode-range: U+50,U+68,U+69,U+6C,U+6F,U+70,U+73,U+79;
  font-display: block;
}

@font-face {
  font-family: 'Unifraktur-Philosophy';
  font-style: normal;
  font-weight: normal;
  src: local('Unifraktur-Philosophy'),
       url("../../fonts/UnifrakturCook-Bold-subset.woff2") format("woff2");
  unicode-range: U+50,U+68,U+69,U+6C,U+6F,U+70,U+73,U+79;
  font-display: block;
}


@font-face {
  font-family: 'VT323-Contemporary-Issues';
  font-style: normal;
  font-weight: normal;
  src: local('VT323-Contemporary-Issues'),
       url("../../fonts/VT323-Regular-subset.woff2") format("woff2");
  unicode-range: U+43,U+49,U+61,U+65,U+6D-70,U+72-75,U+79;
  font-display: block;
}


*/



/* HERO IMAGE AND IMMEDIATE CHILDREN */

#hero {
  position: relative;
  padding: 0;
  color: rgba(0, 0, 0, 0.9);
  border: none;
  background: -webkit-linear-gradient(@heroBoxBackground 0%, @heroBoxBackground 50%, @heroNearBlack 50%, @heroNearBlack 100%);
  background: -o-linear-gradient(@heroBoxBackground 0%, @heroBoxBackground 50%, @heroNearBlack 50%, @heroNearBlack 100%);
  background: linear-gradient(@heroBoxBackground 0%, @heroBoxBackground 50%, @heroNearBlack 50%, @heroNearBlack 100%);
  height: 300px;
  width: 100%;
  font-display: block;

  @media @bigHero {
    height: 600px;
  }
}

.heroTextHalf {
  width: 100%;
  height: 50%;
  text-align: center;
  position: relative;
  overflow: hidden;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-display: block;
}

.heroPhil {
  color: @heroNearBlack;
}

.heroAnd {
  position: absolute;
  text-align: center;
  left: calc(50% - 30px);
  top: calc(50% - 20px - 4px);
  border-radius: 6px;
  height: 40px;
  width: 60px;
  background: rgba(0, 0, 0, 0);
  line-height: 40px;
  font-size: 54px;
  text-shadow: 3px 4px @gold-7;
  color: @gold-5;
  @media @bigHero {
    height: 60px;
    width: 90px;
    font-size: 140px;
    line-height: 60px;
    left: calc(50% - 56px);
    top: calc(50% - 30px - 12px);
    border-radius: 9px;
  }
}

.heroContemp {
  color: @gold-2;
  text-align: right;
}



/* INDIVIDUAL FONT SIZING AND PLACEMENT */

.heroTextHalf > div {
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 0 12px;
}


.heroPhilContent1 {
  font-family: 'Sail-Philosophy';
  font-display: block;
  right: 4px;
  font-size: 56px;
  @media @samsung {
    font-size: 66px;
  }
  @media @bigHero {
    font-size: 108px;
    right: 10px;
  }
  @media @biggerHero {
    font-size: 180px;
    top: -12px;
  }
}

.heroPhilContent2 {
  font-family: 'Unifraktur-Philosophy';
  font-display: block;
  font-size: 62px;
  top: -4px;
  @media @samsung {
    font-size: 70px;
  }
  @media @bigHero {
    top: 6px;
    font-size: 114px;
  }
  @media @biggerHero {
    top: -18px;
    font-size: 180px;
  }
}

.heroPhilContent3 {
  font-family: 'Primitive-Philosophy';
  font-display: block;
  font-size: 48px;
  @media @samsung {
    font-size: 54px;
  }
  @media @bigHero {
    left: 0px;
    top: 18px;
    font-size: 86px;
  }
  @media @biggerHero {
    font-size: 136px;
    top: -12px;
  }
}

.heroPhilContent4 {
  font-family: 'Dalek-Philosophy';
  font-display: block;
  font-size: 50px;
  left: -2px;
  @media @samsung {
    font-size: 54px;
    left: 0px;
  }
  @media @bigHero {
    top: 8px;
    font-size: 92px;
  }
  @media @biggerHero {
    top: -12px;
    font-size: 160px;
  }
}

.heroPhilContent5 {
  font-family: 'AnglicanText-Philosophy';
  font-display: block;
  font-size: 76px;
  line-height: 50px;
  top: -4px;
  @media @samsung {
    font-size: 84px;
  }
  @media @bigHero {
    left: -4px;
    letter-spacing: 1px;
    font-size: 140px;
  }
  @media @biggerHero {
    top: -20px;
    left: -10px;
    font-size: 200px;
  }
}


.heroContempContent1 {
  font-family: 'Halogen-Contemporary-Issues';
  font-display: block;
  font-size: 28px;
  letter-spacing: 0.3em;
  line-height: 42px;
  top: 4px;
  left: 6px;
  @media @bigHero {
    left: 4px;
    top: 10px;
    font-size: 56px;
    letter-spacing: 0.25em;
    line-height: 80px;
  }
  @media @biggerHero {
    top: 0;
    font-size: 94px;
    line-height: 110px;
  }
}

.heroContempContent2 {
  font-family: 'VT323-Contemporary-Issues';
  font-display: block;
  font-size: 56px;
  line-height: 48px;
  top: 4px;
  @media @bigHero {
    top: 6px;
    font-size: 100px;
    line-height: 102px;
  }
  @media @biggerHero {
    top: 0;
    font-size: 150px;
    line-height: 116px;
  }
}

.heroContempContent3 {
  font-family: 'TNG-Contemporary-Issues';
  font-display: block;
  font-size: 40px;
  line-height: 50px;
  top: 8px;
  left: -3px;
  @media @bigHero {
    left: -9px;
    top: 10px;
    letter-spacing: -2px;
    font-size: 80px;
    line-height: 70px;
  }
  @media @biggerHero {
    font-size: 114px;
    line-height: 100px;
  }
}

.heroContempContent4 {
  font-family: 'Ailerons-Contemporary-Issues';
  font-display: block;
  font-size: 38px;
  line-height: 50px;
  top: 6px;
  @media @bigHero {
    top: 10px;
    letter-spacing: -2px;
    font-size: 72px;
    line-height: 90px;
  }
  @media @biggerHero {
    top: 6px;
    font-size: 110px;
    line-height: 120px;
  }
}

.heroContempContent5 {
  text-transform: uppercase;
  font-family: 'Elianto-Contemporary-Issues';
  font-display: block;
  font-size: 31px;
  line-height: 46px;
  top: 3px;
  @media @bigHero {
    top: 0px;
    font-size: 56px;
    line-height: 70px;
  }
  @media @biggerHero {
    font-size: 90px;
    line-height: 100px;
  }
}




.heroContempContent8 {
  font-family: 'StarJedi';
  font-display: block;
  font-size: 38px;
  line-height: 50px;
  top: 10px;
  @media @bigHero {
    left: -20px;
    top: 10px;
    letter-spacing: -3px;
    font-size: 48px;
    line-height: 48px;
  }
}

/* DELETE 7 and 8 if I can't get them working */


.heroPhilHidden { 
  opacity: 0;
  transform: scale(1.2);
}

.heroContempHidden {
  opacity: 0;
  transform: scale(1.2);
}









@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';