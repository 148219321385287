@import '~antd/lib/style/index';
@import '../../../breakpoints';

.skipIntroBtn {
	position: absolute;
	bottom: 12%;
	right: 4%;
	font-size: 5vw;
	height: 8vw;
	opacity: 0;
	transition-duration: 1s;
	@media @lg {
		font-size: 2vw;
		height: 3vw;
	}
}

.skipIntroBtnAnim {
	right: 8%;
	opacity: 1;
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';