@import '~antd/lib/style/index';
@import '../breakpoints';

.detailsContent {
	font-family: Echo, Ping, sans;
	font-size: 16px;
	color: #767676;
	margin-top: 0px;
	margin-bottom: 16px;
	@media @bigHero {
		font-size: 18px;
		margin-top: 0.3em;
		margin-bottom: 8px;
	}
	@media @biggerHero {
		padding: 0px 30px;
		margin-bottom: 1.8em;
	}
}

.detailsReadingTitle {
	font-family: Ping;
	font-size: 18px;
	padding-top: 12spx;
}

.detailsResourceDesc {
	font-family: Echo, Ping, sans;
	font-size: 16px;
	margin-bottom: 2px;
}

.detailsResourceTitle {
	font-family: Ping, Echo, sans;
	font-size: 16px;
}

.detailsResourcesAccordion {
	@media @bigHero {
		margin-top: 30px;
	}
}

.detailsAccordion {
	font-family: Ping, Echo, sans;
	@media @bigHero {
		margin-top: 30px;
	}
}

.detailsEvalDesc {
	font-family: Echo, Ping, sans;
	font-size: 16px;
	margin: 3px;
}

.detailsContactLineText {
	opacity: 0;
	padding-left: 22px;
	@media @samsung {
		opacity: 1;
	}
	@media @xl {
		padding-left: 4px;
	}
}

.detailsContactLineTextVertCenter {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.detailsContactBox {
	font-family: Ping, sans;
	margin-top: 30px;
	@media @bigHero {
		margin-top: 60px;
	}
}

.detailsPoliciesHeading {
	font-family: Ping, Echo, sans;
	padding-top: 16px;
	@media @biggerHero {
		padding-top: 40px;
	}
}

.contentBoxContentWrapper {
	display: flex;
	flex-direction: column;
	@media @sm {
		flex-direction: row;
		justify-content: space-between;
	}
}

.contentBoxTextWrapper {
	font-family: Echo, Ping, sans;
	opacity: 0.96;
	@media @bigHero {
		margin-top: 16px;
	}
}

.contentBoxSiderWrapper {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	@media @sm {
		max-width: 40%;
		min-width: 40%;
	}
}

.readingsBoxWrapper {
	display: flex;
	justify-content: flex-end;
	@media @sm {
		justify-content: flex-end;
	}
}

.readingsBox {
	border: 1px solid #e8e8e8;
	padding: 8px 40px 0px 40px;
	margin-top: 10px;
	width: 100%;
	@media @sm {
		margin-top: 10px;
		margin-bottom: 20px;
		width: 90%;
	}
	@media @bigHero {
		padding: 20px 40px 0px 40px;
	}
}

.writingPromptButton {
	margin-top: 10px;
	border: 1px solid #e8e8e8;
	width: 100%;
	color: rgba(0,0,0,0.55);
	@media @sm {
		width: 90%;
	}
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';