@import '~antd/lib/style/index';
@import '../../breakpoints';

.lectureCard{

	&>p {
		font-family: Echo, Ping, sans;
	}

	&>h3 {
		margin-top: 1em;
	}
}
@primary-color: @gold-6;@link-color: @gold-6;@menu-dark-color: @gold-8;@collapse-header-bg: #fcfaf4;@font-family: Ping, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';